<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue'
import useWindowCacheRefresh from '@/hooks/useWindowCacheRefresh'
import useWindowCacheBust from '@/hooks/useWindowCacheBust'


const refresh = useWindowCacheRefresh()
onMounted(() => useWindowCacheBust(refresh))
</script>
